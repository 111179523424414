<template>
  <div>
    <van-popup v-model="show" @closed="closed" position="bottom" round :style="{ minHeight: '35%', }">

      <div style="padding: 1rem 1rem 3rem 1rem; ">
        <div style="font-size: 1rem;">
          <div style="color: #D33B2E;">共{{ card.cardNum }}票
            <!-- <span style="color: #666;font-size: 0.9rem;">(单票{{ card.movieAmountLimit }}点)</span> -->
          </div>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 1rem; ">
          <span style="font-size: 1rem;">到期日期:{{ card.expireDate }} </span>
        </div>
        <div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 1rem; ">
          <span style="font-size: 1rem;">卡号:{{ card.cardNo }}
          </span>
        </div>
        <div class="flex p-10 jcsb">
          <div></div>
          <div>
            <span style="font-size: 0.85rem; color:#D33B2E ;"> 选择使用票数</span>
            <div style="display: flex;flex-direction: row;align-items: center;margin-top: 0.5rem;">

              <van-icon class="stepperback" size="1.2rem" name="minus" @click="minus" />
              <span class="stepperback" style="margin-left: 0.2rem">
                {{ value }}</span>
              <van-icon class="stepperback" style="margin-left: 0.2rem; " size="1.2rem" name="plus" @click="plus" />
            </div>
            <!-- <van-stepper style="margin-top: 0.8rem;" v-model="card.selectVote" min="1" @change="change"
              :max="card.cardNum" /> -->
          </div>
        </div>
      </div>

      <van-button type="primary" color="#D59A54" class="width-100 btn" @click="sure"
        style="font-size: 1rem;">确认</van-button>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: "",
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      selectIndex: 0,
      show: true,
      value: -1,
    };
  },
  components: {},
  methods: {
    //计步器 加
    plus() {
      if (this.value < this.card.cardNum) {
        this.value += 1
      }
    },
    //减
    minus() {
      if (this.value > 0) {
        this.value -= 1
      }
    },
    closed() {
      this.$emit("closed", false);
    },
    // 步进器事件
    change(value) {
      this.value = value;
    },
    // 购买
    sure() {
      // 添加一个count属性
      this.$emit('selectNum', this.value)
    },

  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    this.value = this.card.selectVote
    window.scroll(0, 0);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.img {
  width: 5rem;
  height: 5rem;
}

.tag {
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  border: 1px solid #666;
  color: #666;
  margin: 0.2rem 1rem;
}

.selectTag {
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin: 0.2rem 1rem;
  border: 1px solid #D33B2E;
  color: #D33B2E;
}

.btn {
  position: absolute;
  bottom: 0;
  height: 2.5rem;
}

.stepperback {
  width: 2rem;
  height: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  align-items: center;
  background-color: #F1F1F1;
}
</style>